const rootPath = "api/contact";
export async function getTenants(token) {
    const response = await fetch(rootPath,
        {
            headers: {
                authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        })
    return response.json();
}

export async function getTenant(portfolioId, token) {
    const response = await fetch(`${rootPath}/${portfolioId}`,
        {
            headers: {
                authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        })
    return response.json();
}

export async function addTenant(portfolio, token) {
    return await fetch(rootPath, {
        method: 'POST',
        headers: {
            authorization: `Bearer ${token}`,
            Accept: 'application/json',
            'Content-Type': 'application/json' 
        },
        body: JSON.stringify(portfolio)
    });
}

export async function editTenant(tenant, id, token) {
    return await fetch(`${rootPath}/${id}`, {
        method: 'PUT',
        headers: {
            authorization: `Bearer ${token}`,
            Accept: 'application/json',
            'Content-Type': 'application/json' 
        },
        body: JSON.stringify(tenant)
    });
}