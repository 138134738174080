import React, {useEffect, useState} from "react";
import {useAuth0} from "@auth0/auth0-react";
import {Link, useNavigate, useParams} from "react-router-dom";
import {getPortfolio} from "../../portfolio/portfolio.service";
import {archiveProperty, getProperty} from "../property.service";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";

const ViewProperty = () => {
    const {id} = useParams();
    const navigate = useNavigate();
    const {getAccessTokenSilently} = useAuth0();
    const initialState = {
        name: '', address1: '', address2: '', city: '', state: '', postalCode: '', portfolioId: 0
    };
    const [property, setProperty] = useState({
        ...initialState
    });
    const [portfolio, setPortfolio] = useState({
        name: ''
    });


    // Modal open state
    const [modal, setModal] = React.useState(false);

    // Toggle for Modal
    const toggle = () => setModal(!modal);

    useEffect(() => {
        const fetchData = async () => {
            const token = await getAccessTokenSilently();
            try {
                const response = await getProperty(id, token);
                setProperty(response);
            } catch (error) {
                console.log("error", error);
            }
        }
        fetchData();
    }, [getAccessTokenSilently, id])

    useEffect(() => {
        const fetchData = async () => {
            if (property.portfolioId !== 0 && property.portfolioId !== null && property.portfolioId !== undefined) {
                const token = await getAccessTokenSilently();
                try {
                    const response = await getPortfolio(property.portfolioId, token);
                    setPortfolio(response);
                } catch (error) {
                    console.log("error", error);
                }
            }
        }
        fetchData();
    }, [getAccessTokenSilently, property.portfolioId])

    const archive = async () => {
        const token = await getAccessTokenSilently();
        await archiveProperty(id, token);
        toggle();
        navigate('/portal/properties');
    }

    return (
        <div>
            <p><Link to='..'> Back to Properties</Link></p>
            <h1>View Property </h1>
            <p>
                <strong>Property Name:</strong> {property.name}
            </p>
            <p>
                <strong>Portfolio:</strong> {portfolio.name}
            </p>
            <p>
                <strong>Address 1:</strong> {property.address1}
            </p>
            <p>
                <strong>Address 2:</strong> {property.address2}
            </p>
            <p>
                <strong>City:</strong> {property.city}
            </p>
            <p>
                <strong>State:</strong> {property.state}
            </p>
            <p>
                <strong>Postal Code:</strong> {property.postalCode}
            </p>
            <p>
                <Button color='danger' outline onClick={toggle}>Archive</Button>
            </p>
            <Modal isOpen={modal} toggle={toggle}>
                <ModalHeader toggle={toggle}>Archive Property</ModalHeader>
                <ModalBody>
                    Archiving a property means it is not editable and can not be managed. Are you sure you want to
                    archive this property?
                </ModalBody>
                <ModalFooter>
                    <Button color="danger" onClick={archive}>Archive</Button>{' '}
                    <Button color="secondary" onClick={toggle}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </div>
    )
}

export default ViewProperty;