import React, {useEffect, useState} from "react";
import {Auth0Provider} from "@auth0/auth0-react";
import App from "./App";
import {useNavigate} from "react-router-dom";
import {getAuthConfig} from "./configuration/config.service";

export default function AppAuthWrapper() {

    const [authConfig, setAuthConfig] = useState({domain: '', clientId: '', audience: ''});
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await getAuthConfig();
                setAuthConfig(response);
            } catch (error) {
                console.log("error", error);
            }
        }
        fetchData();
    }, [])
    const onRedirectCallback = (appState) => {
        navigate(
            appState && appState.returnTo
                ? appState.returnTo
                : window.location.href
        );

    };
    if (authConfig.domain === '') {
        return (<></>)
    }

    return (
        <Auth0Provider
            domain={authConfig.domain}
            clientId={authConfig.clientId}
            redirectUri={window.location.origin}
            onRedirectCallback={onRedirectCallback}
            audience={authConfig.audience}
            scope='openid profile email'
        >
            <App/>
        </Auth0Provider>
    );
}
