import {useEffect, useState} from "react";
import {getPortfolios} from "./portfolio.service";
import {useAuth0} from "@auth0/auth0-react";
import {Button, Card, CardTitle, Col, Row} from "reactstrap";
import {Link} from 'react-router-dom';
import {HousesFill} from "react-bootstrap-icons";

export default function Portfolios() {
    const {getAccessTokenSilently} = useAuth0();
    const [portfolio, setPortfolio] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            const token = await getAccessTokenSilently();
            try {
                const response = await getPortfolios(token);
                setPortfolio(response);
            } catch (error) {
                console.log("error", error);
            }
        }
        fetchData();
    }, [getAccessTokenSilently])

    return (
        <div>
            <Row className='mb-2'>
                <Col>
                    <h1>Portfolios</h1>
                </Col>
                <Col sm={1} style={{marginBottom: '12px'}} className='align-items-right'>
                    <Link to='create'
                          className='btn btn-primary'
                          style={{width: '100px'}}>
                        <HousesFill size='20' className='align-middle'/>
                        <span className='align-middle'>+</span>
                    </Link>
                </Col>
            </Row>
            <Row>
                {portfolio.map(portfolio =>
                    <Col sm="4" key={portfolio.id} style={{marginBottom: '12px'}}>
                        <Card body>
                            <CardTitle tag="h5">
                                {portfolio.name}
                            </CardTitle>
                            <Row>
                                <Link to={`${portfolio.id}`} className='col-lg-6 d-grid'>
                                    <Button color='primary' className='btn-block'>
                                        View
                                    </Button>
                                </Link>
                                <Link to={`${portfolio.id}/edit`} className='col-lg-6 d-grid'>
                                    <Button color='success' className='btn-block'>
                                        Edit
                                    </Button>
                                </Link>
                            </Row>
                        </Card>
                    </Col>
                )}
            </Row>
        </div>
    );
}