import {Button, Form, FormGroup, Input, Label, Row} from "reactstrap";
import {useEffect, useState} from "react";
import {useAuth0} from "@auth0/auth0-react";
import {Link, useParams} from "react-router-dom";
import {editMortgage, getMortgage} from "../mortgage.service";

const EditMortgage = () => {
    const {id} = useParams();

    const initialState = {
        active: '', lenderName: '', webpage: '', monthlyAmount: '', phone: '', phoneExt: ''
    };
    const [mortgage, setMortgage] = useState({...initialState});
    const [submitted, setSubmitted] = useState(false);
    const {getAccessTokenSilently} = useAuth0();

    useEffect(() => {
        const fetchData = async () => {
            const token = await getAccessTokenSilently();
            try {
                const response = await getMortgage(id, token);
                setMortgage(response);
            } catch (error) {
                console.log("error", error);
            }
        }
        fetchData();
    }, [getAccessTokenSilently, id])

    const handleSubmit = async (e) => {
        e.preventDefault();
        const token = await getAccessTokenSilently();
        await editMortgage(id, mortgage, token);
        setSubmitted(true);
    };

    const handleInputChange = (event, field) => {
        setMortgage((values) => ({
            ...values,
            [field]: event.target.value,
        }));
    };

    return (
        <div>
            <p><Link to='..'> Back to Mortgages</Link></p>

            <Row>
                <h1>Add New Mortgage</h1>
            </Row>

            <Form onSubmit={handleSubmit}>
                <Row>
                    <FormGroup className='col-md-6'>
                        <Label for="lenderNameInput">
                            Lender Name
                        </Label>
                        <Input
                            id="lenderNameInput"
                            className="form-field"
                            name="lenderName"
                            value={mortgage.lenderName}
                            required
                            onChange={(event) => handleInputChange(event, 'lenderName')}
                        />
                        <div className="invalid-tooltip">
                            Please provide a lender name.
                        </div>
                    </FormGroup>
                </Row>
                <Row>
                    <FormGroup className='col-md-6'>
                        <Label for="webpageInput">
                            Address
                        </Label>
                        <Input
                            id="webpageInput"
                            className="form-field"
                            name="webpage"
                            value={mortgage.webpage}
                            onChange={(event) => handleInputChange(event, 'webpage')}
                        />
                        <div className="invalid-tooltip">
                            Please provide a webpage.
                        </div>
                    </FormGroup>
                    <FormGroup className='col-md-6'>
                        <Label for="monthlyAmountInput">
                            Monthly Payment Amount
                        </Label>
                        <Input
                            id="monthlyAmountInput"
                            name="monthlyAmount"
                            value={mortgage.monthlyAmount}
                            onChange={(event) => handleInputChange(event, 'monthlyAmount')}
                        />
                    </FormGroup>
                </Row>
                <Row>
                    <FormGroup className='col-md-6'>
                        <Label for="phoneInput">
                            Phone
                        </Label>
                        <Input
                            id="phoneInput"
                            className="form-field"
                            name="phone"
                            value={mortgage.phone}
                            onChange={(event) => handleInputChange(event, 'phone')}
                        />
                    </FormGroup>
                    <FormGroup className='col-md-6'>
                        <Label for="phoneExtInput">
                            Monthly Payment Amount
                        </Label>
                        <Input
                            id="phoneExtInput"
                            name="phoneExt"
                            value={mortgage.phoneExt}
                            onChange={(event) => handleInputChange(event, 'phoneExt')}
                        />
                    </FormGroup>
                </Row>
                <Button color="primary" size='lg'>
                    Submit
                </Button>
                {submitted && <div className='success-message'>Success!registering</div>}
            </Form>
        </div>
    )
}

export default EditMortgage;