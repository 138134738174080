export async function getProperties(token) {
    const response = await fetch('api/property',
        {
            headers: {
                authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        })
    return response.json();
}

export async function getProperty(id, token) {
    const response = await fetch(`api/property/${id}`,
        {
            headers: {
                authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        })
    return response.json();
}

export async function addProperty(property, token) {
    return await fetch('api/property', {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(property)
    });
}

export async function editProperty(id, property, token) {
    return await fetch(`api/property/${id}`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(property)
    });
}

export async function archiveProperty(id, token) {
    return await fetch(`api/property/${id}`, {
        method: 'DELETE',
        headers: {
            Accept: 'application/json',
            authorization: `Bearer ${token}`
        }
    });
}