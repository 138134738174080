import {useEffect, useState} from "react";
import {getProperties} from "./property.service";
import {
    Card,
    CardText,
    CardTitle,
    Col,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Row, UncontrolledDropdown
} from "reactstrap";
import {useAuth0} from "@auth0/auth0-react";
import {getPortfolios} from "../portfolio/portfolio.service";
import {HousesFill} from "react-bootstrap-icons";
import {Link} from 'react-router-dom';
import KebabVertical from "../../components/verticalKebab/KebabVertical";


export default function Properties() {
    const [properties, setProperties] = useState([]);
    const [portfolios, setPortfolios] = useState([]);
    const {getAccessTokenSilently} = useAuth0();

    useEffect(() => {
        const fetchData = async () => {
            const token = await getAccessTokenSilently();
            try {
                const response = await getProperties(token);
                setProperties(response);
            } catch (error) {
                console.log("error", error);
            }
        }
        fetchData();
    }, [getAccessTokenSilently])

    useEffect(() => {
        const fetchData = async () => {
            const token = await getAccessTokenSilently();
            try {
                const response = await getPortfolios(token);
                setPortfolios(response);
            } catch (error) {
                console.log("error", error);
            }
        }
        fetchData();
    }, [getAccessTokenSilently])

    return (
        <div>
            <Row className='mb-2'>
                <Col>
                    <h1>Properties</h1>
                </Col>
                <Col sm={1} style={{marginBottom: '12px'}} className='align-items-right'>
                    <Link to='create'
                          className='btn btn-primary'
                          style={{width: '100px'}}>
                        <HousesFill size='20' className='align-middle'/>
                        <span className='align-middle'>+</span>
                    </Link>
                </Col>
            </Row>
            <Row>
                {properties.map(property =>
                    <Col sm="4" key={property.id} style={{marginBottom: '12px'}}>
                        <Card body>
                            <CardTitle tag="h5">
                                {property.name || property.address1}
                                <UncontrolledDropdown className="float-end">
                                    <DropdownToggle
                                        id="dropdownMenuButton"
                                        tag="a" className="nav-link btn-circle"
                                        style={{cursor: 'pointer'}}
                                    >
                                        <KebabVertical/>
                                    </DropdownToggle>

                                    <DropdownMenu aria-labelledby="dropdownMenuButton" className="dropdown-menu-dark">
                                        <DropdownItem tag={Link} to={`${property.id}`}>
                                            View
                                        </DropdownItem>

                                        <DropdownItem tag={Link} to={`${property.id}/edit`}>
                                            Edit
                                        </DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>

                            </CardTitle>
                            <CardText>
                                {property.city}, {property.state}

                            </CardText>
                            <Row>
                                <div className='float-md-end text-end align-middle mt-2'>
                                    <HousesFill className='align-middle'/> {'  '}
                                    <span
                                        className='align-middle'>{portfolios.find(p => p.id === property.portfolioId)?.name || ''}</span>
                                </div>
                            </Row>
                        </Card>
                    </Col>
                )}
            </Row>
        </div>
    );
}