import React from 'react';
import {Container,} from 'reactstrap';
import {NavMenu} from '../portal/nav-menu/NavMenu';
import {Outlet} from "react-router-dom";
import {useAuth0} from "@auth0/auth0-react";

export default function PortalLayout(props) {
    const {isAuthenticated} = useAuth0();

    return isAuthenticated && (
        <div className="d-flex portalRoot">
            <NavMenu/>
            <Container fluid tag="main" className="contentContainer" style={{height: '100%'}}>
                <Outlet/>
            </Container>
        </div>
    );
}
