import {Button, Form, FormGroup, Input, Label} from "reactstrap";
import {useEffect, useState} from "react";
import {editPortfolio, getPortfolio} from "../portfolio.service";
import {useAuth0} from "@auth0/auth0-react";
import {Link, useParams} from "react-router-dom";

const EditPortfolio = () => {
    const {id} = useParams();
    const {getAccessTokenSilently} = useAuth0();
    const initialState = {
        name: ''
    };
    const [portfolio, setPortfolio] = useState({
        ...initialState
    });

    const [submitted, setSubmitted] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            const token = await getAccessTokenSilently();
            try {
                const response = await getPortfolio(id, token);
                setPortfolio(response);
            } catch (error) {
                console.log("error", error);
            }
        }
        fetchData();
    }, [getAccessTokenSilently, id]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const token = await getAccessTokenSilently();
        await editPortfolio(portfolio, id, token);
        setSubmitted(true);
    };

    const handleInputChange = (event, field) => {
        setPortfolio((values) => ({
            ...values,
            [field]: event.target.value,
        }));
    };

    return (
        <div>
            <p><Link to='..'> Back to Portfolios</Link></p>
            <Form onSubmit={handleSubmit}>
                <FormGroup>
                    <Label for="nameInput">
                        Name
                    </Label>
                    <Input
                        id="nameInput"
                        className="form-field"
                        name="name"
                        value={portfolio.name}
                        onChange={(event) => handleInputChange(event, 'name')}
                        required
                    />
                    <div className="invalid-tooltip">
                        Please provide a valid name.
                    </div>
                </FormGroup>
                <Button color="primary">
                    Submit
                </Button>
                {submitted && <div className='success-message'>Success!</div>}
            </Form>
        </div>
    )
}

export default EditPortfolio;