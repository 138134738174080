import Home from "./home/Home";
import PortalLayout from "./components/PortalLayout";
import {PortalAppRoutes} from "./portal/PortalAppRoutes";

const AppRoutes = [
    {
        index: true,
        element: <Home/>
    },
    {
        element: <PortalLayout/>,
        path: 'portal',
        children: PortalAppRoutes
    },
];

export default AppRoutes;
