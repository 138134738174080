import {Button, Form, FormGroup, Input, Label} from "reactstrap";
import {useState} from "react";
import {addPortfolio} from "../portfolio.service";
import {useAuth0} from "@auth0/auth0-react";
import {Link} from 'react-router-dom';

const AddPortfolio = () => {
    const {getAccessTokenSilently} = useAuth0();
    const initialState = {
        name: ''
    };
    const [portfolio, setPortfolio] = useState({
        ...initialState
    });

    const [submitted, setSubmitted] = useState(false);
    const handleSubmit = async (e) => {
        e.preventDefault();
        const token = await getAccessTokenSilently();
        await addPortfolio(portfolio, token);
        setSubmitted(true);
        setPortfolio({...initialState});
    };

    const handleInputChange = (event, field) => {
        setPortfolio((values) => ({
            ...values,
            [field]: event.target.value,
        }));
    };

    return (
        <div>
            <p><Link to='..'> Back to Portfolios</Link></p>
            <Form onSubmit={handleSubmit}>
                <FormGroup>
                    <Label for="nameInput">
                        Name
                    </Label>
                    <Input
                        id="nameInput"
                        className="form-field"
                        name="name"
                        value={portfolio.name}
                        onChange={(event) => handleInputChange(event, 'name')}
                        required
                    />
                    <div className="invalid-tooltip">
                        Please provide a valid name.
                    </div>
                </FormGroup>
                <Button color="primary">
                    Submit
                </Button>
                {submitted && <div className='success-message'>Success! Thank you for registering</div>}
            </Form>
        </div>
    )
}

export default AddPortfolio;