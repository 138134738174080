import React from "react";
import {useAuth0} from "@auth0/auth0-react";

const Profile = () => {
    const {user, isLoading} = useAuth0();

    if (isLoading) {
        return <div>Loading ...</div>;
    }

    return (
        <div>
            <h2>{user.name}</h2>
            <p>
                <img src={user.picture} alt={user.name}/>
            </p>
            <p>{user.email}</p>
        </div>
    );


};

export default Profile;