import {useEffect, useState} from "react";
import {useAuth0} from "@auth0/auth0-react";
import {Link, useParams} from "react-router-dom";
import {getTenant} from "../tenant.service";

const ViewTenant = () => {
    const {id} = useParams();
    const {getAccessTokenSilently} = useAuth0();
    const initialState = {
        firstName: '', middleName: '', lastName: '', phone1: '', email: ''
    };
    const [tenant, setTenant] = useState({
        ...initialState
    });

    useEffect(() => {
        const fetchData = async () => {
            const token = await getAccessTokenSilently();
            try {
                const response = await getTenant(id, token);
                setTenant(response);
            } catch (error) {
                console.log("error", error);
            }
        }
        fetchData();
    }, [getAccessTokenSilently, id])

    return (
        <div>
            <p><Link to='..'> Back to Tenant</Link></p>

            <h1>View Tenant </h1>
            <p>
                <strong>First Name:</strong>
                {tenant.firstName}
            </p>
            <p>
                <strong>Middle Name:</strong>
                {tenant.middleName}
            </p>
            <p>
                <strong>Last Name:</strong>
                {tenant.lastName}
            </p>
            <p>
                <strong>Phone:</strong>
                {tenant.phone1}
            </p>
            <p>
                <strong>Email:</strong>
                {tenant.email}
            </p>
        </div>
    )
}

export default ViewTenant;