import React, {useEffect, useState} from 'react';
import PropertyCards from "./PropertyCard/PropertyCards";
import {useAuth0} from "@auth0/auth0-react";
import {getProperties} from "../property/property.service";

export default function PortalHome() {
    const [properties, setProperties] = useState([]);
    const {getAccessTokenSilently} = useAuth0();

    useEffect(() => {
        const fetchData = async () => {
            const token = await getAccessTokenSilently();
            try {
                const response = await getProperties(token);
                setProperties(response);
            } catch (error) {
                console.log("error", error);
            }
        }
        fetchData(getAccessTokenSilently);
    }, [getAccessTokenSilently])
    return (<div>
        <h1>Hello, Property Fans!</h1>
        <p>On Wednesdays we wear pink</p>
        <PropertyCards properties={properties}/>

    </div>);
}
