import {Button, Form, FormGroup, Input, Label} from "reactstrap";
import {useState} from "react";
import {useAuth0} from "@auth0/auth0-react";
import {Link} from 'react-router-dom';
import {addTenant} from "../tenant.service";

const AddTenant = () => {
    const {getAccessTokenSilently} = useAuth0();
    const initialState = {
        firstName: '', middleName: '', lastName: '', phone1: '', email: ''
    };
    const [tenant, setTenant] = useState({
        ...initialState
    });

    const [submitted, setSubmitted] = useState(false);
    const handleSubmit = async (e) => {
        e.preventDefault();
        const token = await getAccessTokenSilently();
        await addTenant(tenant, token);
        setSubmitted(true);
        setTenant({...initialState});
    };

    const handleInputChange = (event, field) => {
        setTenant((values) => ({
            ...values,
            [field]: event.target.value,
        }));
    };

    return (
        <div>
            <p><Link to='..'> Back to Tenants</Link></p>
            <Form onSubmit={handleSubmit}>
                <FormGroup>
                    <Label for="firstNameInput">
                        First Name
                    </Label>
                    <Input
                        id="firstNameInput"
                        className="form-field"
                        name="firstName"
                        value={tenant.firstName}
                        onChange={(event) => handleInputChange(event, 'firstName')}
                        required
                    />
                    <div className="invalid-tooltip">
                        Please provide a valid first name.
                    </div>
                </FormGroup>
                <FormGroup>
                    <Label for="middleNameInput">
                        Middle Name
                    </Label>
                    <Input
                        id="middleNameInput"
                        className="form-field"
                        name="middleName"
                        value={tenant.middleName}
                        onChange={(event) => handleInputChange(event, 'middleName')}
                    />
                    <div className="invalid-tooltip">
                        Please provide a valid middle name.
                    </div>
                </FormGroup>
                <FormGroup>
                    <Label for="lastNameInput">
                        Last Name
                    </Label>
                    <Input
                        id="lastNameInput"
                        className="form-field"
                        name="lastName"
                        value={tenant.lastName}
                        onChange={(event) => handleInputChange(event, 'lastName')}
                        required
                    />
                    <div className="invalid-tooltip">
                        Please provide a valid last name.
                    </div>
                </FormGroup>
                <FormGroup>
                    <Label for="phone1Input">
                        Phone Number
                    </Label>
                    <Input
                        id="phone1Input"
                        className="form-field"
                        name="phone1"
                        value={tenant.phone1}
                        onChange={(event) => handleInputChange(event, 'phone1')}
                    />
                    <div className="invalid-tooltip">
                        Please provide a valid phone number.
                    </div>
                </FormGroup>
                <FormGroup>
                    <Label for="emailInput">
                        Email
                    </Label>
                    <Input
                        id="emailInput"
                        className="form-field"
                        type="email"
                        name="email"
                        value={tenant.email}
                        onChange={(event) => handleInputChange(event, 'email')}
                        required
                    />
                    <div className="invalid-tooltip">
                        Please provide a valid email address.
                    </div>
                </FormGroup>
                <Button color="primary">
                    Submit
                </Button>
                {submitted && <div className='success-message'>Success!</div>}
            </Form>
        </div>
    )
}

export default AddTenant;