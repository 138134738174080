import {Button, Form, FormGroup, Input, Label, Row} from "reactstrap";
import {stateValueLabels} from "../../../consts";
import React, {useEffect, useState} from "react";
import {editProperty, getProperty} from "../property.service";
import {useAuth0} from "@auth0/auth0-react";
import {Link, useParams} from "react-router-dom";

const EditProperty = () => {
    const {id} = useParams();

    const initialState = {
        name: '', address1: '', address2: '', city: '', state: '', postalCode: ''
    };
    const [property, setProperty] = useState({...initialState});
    const [submitted, setSubmitted] = useState(false);
    const {getAccessTokenSilently} = useAuth0();

    useEffect(() => {
        const fetchData = async () => {
            const token = await getAccessTokenSilently();
            try {
                const response = await getProperty(id, token);
                setProperty(response);
            } catch (error) {
                console.log("error", error);
            }
        }
        fetchData();
    }, [getAccessTokenSilently, id])

    const handleSubmit = async (e) => {
        e.preventDefault();
        const token = await getAccessTokenSilently();
        await editProperty(id, property, token);
        setSubmitted(true);
    };

    const handleInputChange = (event, field) => {
        setProperty((values) => ({
            ...values,
            [field]: event.target.value,
        }));
    };

    return (
        <div>
            <p><Link to='..'> Back to Properties</Link></p>
            <Row>
                <h1>Edit Property</h1>
            </Row>

            <Form onSubmit={handleSubmit}>
                <Row>
                    <FormGroup className='col-md-6'>
                        <Label for="nameInput">
                            Property Name
                        </Label>
                        <Input
                            id="nameInput"
                            className="form-field"
                            name="propertyName"
                            value={property.name || ''}
                            onChange={(event) => handleInputChange(event, 'name')}
                        />
                        <div className="invalid-tooltip">
                            Please provide a valid name.
                        </div>
                    </FormGroup>
                </Row>
                <Row>
                    <FormGroup className='col-md-6'>
                        <Label for="address1Input">
                            Address
                        </Label>
                        <Input
                            id="address1Input"
                            className="form-field"
                            name="address1"
                            value={property.address1 || ''}
                            onChange={(event) => handleInputChange(event, 'address1')}
                            required
                        />
                        <div className="invalid-tooltip">
                            Please provide a valid address.
                        </div>
                    </FormGroup>
                    <FormGroup className='col-md-6'>
                        <Label for="address2Input">
                            Address Additional
                        </Label>
                        <Input
                            id="address2Input"
                            name="address2"
                            value={property.address2 || ''}
                            onChange={(event) => handleInputChange(event, 'address2')}
                        />
                    </FormGroup>
                </Row>
                <Row>
                    <FormGroup className='col-md-4'>
                        <Label for="cityInput">
                            City
                        </Label>
                        <Input
                            id="cityInput"
                            name="city"
                            value={property.city || ''}
                            onChange={(event) => handleInputChange(event, 'city')}
                            required
                        />
                        <div className="invalid-tooltip">
                            Please provide a valid city.
                        </div>
                    </FormGroup>
                    <FormGroup className='col-md-4'>
                        <Label for="state">
                            State
                        </Label>
                        <Input
                            id="state"
                            name="state"
                            type="select"
                            value={property.state || ''}
                            onChange={(event) => handleInputChange(event, 'state')}
                            required>
                            {stateValueLabels.map((state, index) => {
                                return <option key={index} value={state.value}>{state.label}</option>
                            })}
                        </Input>
                        <div className="invalid-tooltip">
                            Please select a state.
                        </div>
                    </FormGroup>
                    <FormGroup className='col-md-4'>
                        <Label for="postalCodeInput">
                            Postal Code
                        </Label>
                        <Input
                            id="postalCodeInput"
                            name="postalCode"
                            value={property.postalCode || ''}
                            onChange={(event) => handleInputChange(event, 'postalCode')}
                            required
                        />
                        <div className="invalid-tooltip">
                            Please provide a valid zip.
                        </div>
                    </FormGroup>
                </Row>
                <Button color="primary" size='lg'>
                    Submit
                </Button>
                {submitted && <div className='success-message'>Success!</div>}
            </Form>
        </div>
    )
}

export default EditProperty;