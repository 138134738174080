import {useEffect, useState} from "react";
import {Col, DropdownItem, DropdownMenu, DropdownToggle, Row, Table, UncontrolledDropdown} from "reactstrap";
import {useAuth0} from "@auth0/auth0-react";
import {HousesFill} from "react-bootstrap-icons";
import SnapSpinner from "../../components/spinner/SnapSpinner";
import KebabVertical from "../../components/verticalKebab/KebabVertical";
import {Link} from 'react-router-dom';
import {getTenants} from "./tenant.service";


export default function Tenants() {
    const [isLoading, setIsLoading] = useState(true);
    const [tenants, setTenants] = useState([]);
    const {getAccessTokenSilently} = useAuth0();

    useEffect(() => {
        const fetchData = async () => {
            const token = await getAccessTokenSilently();
            try {
                const response = await getTenants(token);
                setTenants(response);
            } catch (error) {
                console.log("error", error);
            } finally {
                setIsLoading(false);
            }
        }
        fetchData();
    }, [getAccessTokenSilently])

    if (isLoading) {
        return <SnapSpinner/>;
    }

    const tenantRows = tenants.length ? (tenants.map(tenant =>
        <tr key={tenant.id}>
            <td>{tenant.id}</td>
            <td>{tenant.firstName} {tenant.lastName}</td>
            <td>{tenant.phone1}</td>
            <td>{tenant.email}</td>
            <td>
                <UncontrolledDropdown className="float-end" direction='left'>
                    <DropdownToggle
                        id="dropdownMenuButton"
                        tag="a" className="nav-link btn-circle"
                        style={{cursor: 'pointer'}}
                    >
                        <KebabVertical/>
                    </DropdownToggle>

                    <DropdownMenu aria-labelledby="dropdownMenuButton" className="dropdown-menu-dark">
                        <DropdownItem tag={Link} to={`${tenant.id}`}>
                            View
                        </DropdownItem>

                        <DropdownItem tag={Link} to={`${tenant.id}/edit`}>
                            Edit
                        </DropdownItem>
                    </DropdownMenu>
                </UncontrolledDropdown>
            </td>
        </tr>)) : null;

    return (
        <div style={{height: '100%'}}>
            <Row className='mb-2'>
                <Col>
                    <h1>Tenants</h1>
                </Col>
                <Col sm={1} style={{marginBottom: '12px'}} className='align-items-right'>
                    <Link to='create'
                          className='btn btn-primary'
                          style={{width: '100px'}}>
                        <HousesFill size='20' className='align-middle'/>
                        <span className='align-middle'>+</span>
                    </Link>
                </Col>
            </Row>
            <Table responsive>
                <thead>
                <tr>
                    <th>#</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Phone</th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                {tenantRows}

                </tbody>
            </Table>
        </div>
    );
}