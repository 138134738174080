import PropertyCard from "./PropertyCard";
import {Row} from "reactstrap";
import React from "react";

const PropertyCards = ({properties}) => {
    return (<Row>
        {properties.map((property, index) => {
            return (<PropertyCard property={property} key={index}/>)
        })}
    </Row>)
}

export default PropertyCards;