export async function getMortgages(token) {
    const response = await fetch('api/mortgage',
        {
            headers: {
                authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        })
    return response.json();
}

export async function getMortgage(id, token) {
    const response = await fetch(`api/mortgage/${id}`,
        {
            headers: {
                authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        })
    return response.json();
}

export async function addMortgage(mortgage, token) {
    return await fetch('api/mortgage', {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(mortgage)
    });
}

export async function editMortgage(id, mortgage, token) {
    return await fetch(`api/mortgage/${id}`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(mortgage)
    });
}
