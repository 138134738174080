import {Navigate, Outlet} from "react-router-dom";
import {useAuth0} from "@auth0/auth0-react";
import React from "react";

export default function ProtectedRoute() {
    const {isAuthenticated, isLoading} = useAuth0();
    if (isLoading) {
        return <div>Loading ... Bruh</div>;
    }
    if (!isAuthenticated) {
        return <Navigate to="/" replace/>;
    }

    return <Outlet />;
};