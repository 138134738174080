import React from "react";
import {Spinner} from 'reactstrap'
import './SnapSpinner.css';

const SnapSpinner = () => {
    return (
        <Spinner color="primary" className="snapSpinner">
            Loading...
        </Spinner>)
}
export default SnapSpinner;
