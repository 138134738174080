import React, {useEffect, useState} from "react";
import {useAuth0} from "@auth0/auth0-react";
import {useParams} from "react-router-dom";
import {getMortgage} from "../mortgage.service";
import {getProperty} from "../../property/property.service";
import {Link} from 'react-router-dom';

const ViewMortgage = () => {
    const {id} = useParams();
    const {getAccessTokenSilently} = useAuth0();
    const initialState = {
        active: '', lenderName: '', webpage: '', monthlyAmount: '', phone: '', phoneExt: '', propertyId: ''
    };
    const [mortgage, setMortgage] = useState({
        ...initialState
    });
    const [property, setProperty] = useState({
        name: ''
    });

    useEffect(() => {
        const fetchData = async () => {
            const token = await getAccessTokenSilently();
            try {
                const response = await getMortgage(id, token);
                setMortgage(response);
            } catch (error) {
                console.log("error", error);
            }
        }
        fetchData();
    }, [getAccessTokenSilently, id])

    useEffect(() => {
        const fetchData = async () => {
            if (mortgage.propertyId !== 0 && mortgage.propertyId !== null && mortgage.propertyId !== undefined) {
                const token = await getAccessTokenSilently();
                try {
                    const response = await getProperty(mortgage.propertyId, token);
                    setProperty(response);
                } catch (error) {
                    console.log("error", error);
                }
            }
        }
        fetchData();
    }, [getAccessTokenSilently, mortgage.propertyId])

    return (
        <div>
            <Link to='..'> Back to Mortgages</Link>
            <h1>View Mortgage </h1>
            <p>
                <strong>Property:</strong> {property.name}
            </p>
            <p>
                <strong>Monthly Payment Amount:</strong> {mortgage.monthlyAmount}
            </p>
            <p>
                <strong>Lender Name: </strong> {mortgage.lenderName}
            </p>
            <p>
                <strong>Webpage:</strong> {mortgage.webpage}
            </p>
            <p>
                <strong>Phone:</strong> {mortgage.phone}
            </p>
            <p>
                <strong>Phone Extension:</strong> {mortgage.phoneExt}
            </p>
        </div>
    )
}

export default ViewMortgage;