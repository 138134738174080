import {Card, CardBody, CardSubtitle, CardText, CardTitle, Col} from "reactstrap";
import React from "react";

const PropertyCard = ({property}) => {
    return (<Col sm="4">
        <Card
            outline
            style={{margin: '5px'}}>
            <CardBody>
                <CardTitle tag="h5">
                    {property.address1}
                </CardTitle>
                <CardSubtitle
                    className="mb-2 text-muted"
                    tag="h6">
                    {property.address2 || <span>&nbsp;&nbsp;</span>}
                </CardSubtitle>
                <CardText>
                    {property.city}, {property.state} {property.postalCode}
                </CardText>
            </CardBody>
        </Card>
    </Col>)
}
export default PropertyCard;