import React from "react";
import './Home.css';
import {useAuth0} from "@auth0/auth0-react";
import {Container} from "reactstrap";
export default function Home() {
    const { loginWithRedirect } = useAuth0();

    return (
        <div>
            <header className="masthead d-flex align-items-center">
                <Container fluid className="px-4 px-lg-5 text-center pb-3"
                style={{ background: 'rgba(0, 0, 0, 0.5)'}}>
                    <h1 className="mb-1">Property Snap</h1>
                    <h3 className="mb-3"><em>Property Management Snapshot</em></h3>
                    <button className="btn btn-primary btn-lg" onClick={() => loginWithRedirect({
                        appState: {
                            returnTo: 'portal'
                        }
                    })}>Enter Home</button>
                </Container>
            </header>
        </div>
    );
}
