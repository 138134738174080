export async function getPortfolios(token) {
    const response = await fetch('api/portfolio',
        {
            headers: {
                authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        })
    return response.json();
}

export async function getPortfolio(portfolioId, token) {
    const response = await fetch(`api/portfolio/${portfolioId}`,
        {
            headers: {
                authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        })
    return response.json();
}

export async function addPortfolio(portfolio, token) {
    return await fetch('api/portfolio', {
        method: 'POST',
        headers: {
            authorization: `Bearer ${token}`,
            Accept: 'application/json',
            'Content-Type': 'application/json' 
        },
        body: JSON.stringify(portfolio)
    });
}

export async function editPortfolio(portfolio, id, token) {
    return await fetch(`api/portfolio/${id}`, {
        method: 'PUT',
        headers: {
            authorization: `Bearer ${token}`,
            Accept: 'application/json',
            'Content-Type': 'application/json' 
        },
        body: JSON.stringify(portfolio)
    });
}