import {useEffect, useState} from "react";
import {getPortfolio} from "../portfolio.service";
import {useAuth0} from "@auth0/auth0-react";
import {Link, useParams} from "react-router-dom";

const ViewPortfolio = () => {
    const {id} = useParams();
    const {getAccessTokenSilently} = useAuth0();
    const initialState = {
        name: ''
    };
    const [portfolio, setPortfolio] = useState({
        ...initialState
    });

    useEffect(() => {
        const fetchData = async () => {
            const token = await getAccessTokenSilently();
            try {
                const response = await getPortfolio(id, token);
                setPortfolio(response);
            } catch (error) {
                console.log("error", error);
            }
        }
        fetchData();
    }, [getAccessTokenSilently, id])

    return (
        <div>
            <p><Link to='..'> Back to Portfolios</Link></p>

            <h1>View Portfolio </h1>
            <p>
                <strong>Portfolio Name:</strong>
                {portfolio.name}
            </p>
        </div>
    )
}

export default ViewPortfolio;