import React from 'react';
import {Outlet, Route, Routes} from 'react-router-dom';
import AppRoutes from './AppRoutes';
import './custom.css';
import ProtectedRoute from "./components/ProtectedRoute";

export default function App() {
    return (
            <Routes>
                {AppRoutes.map((route, index) => {

                    return buildRoute(route, index)
                })}
            </Routes>
    );
}

function buildRoute({element, protect, children = [], ...rest}, index) {
    return protect ?
        (<Route key={index} {...rest} element={<ProtectedRoute/>}>
            <Route {...rest} element={element || <Outlet/>}>
                {children.map((route, index1) => {
                    return buildRoute(route, index1)
                })}
            </Route>
        </Route>)
        : (<Route key={index} {...rest} element={element || <Outlet/>}>
            {children.map((route, index1) => {
                return buildRoute(route, index1)
            })}
        </Route>);
}