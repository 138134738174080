import React, {useState} from 'react';
import {
    Collapse,
    Dropdown, DropdownItem,
    DropdownMenu,
    DropdownToggle, Nav,
    Navbar,
    NavbarBrand,
    NavbarToggler,
    NavItem,
    NavLink
} from 'reactstrap';
import {NavLink as RRNavLink} from 'react-router-dom';
import './NavMenu.css';
import {useAuth0} from "@auth0/auth0-react";

export function NavMenu() {
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => setIsOpen(!isOpen);


    const {logout, user} = useAuth0();
    const [profileDropdownOpen, setProfileDropdownOpen] = useState(false);

    const profileDropdownToggle = () => setProfileDropdownOpen(!profileDropdownOpen);

    const logoutUser = () => logout({returnTo: window.location.origin})

    return (
        <Navbar color="dark" dark expand="md" className="flex-grow-0 flex-shrink-0 flex-column ExampleNavbar">
            <div style={{width: "100%"}}>
                <NavbarBrand tag={RRNavLink} to="/portal" onClick={isOpen ? toggle : null}>
                    Property Snap
                </NavbarBrand>
                <NavbarToggler onClick={toggle}/>
            </div>
            <Collapse isOpen={isOpen} navbar className="flex-column align-items-start">
                <Nav className="ml-auto navbar-nav flex-column" navbar>
                    <NavItem>
                        <NavLink tag={RRNavLink} to="/portal/portfolios" onClick={toggle}>Portfolios</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink tag={RRNavLink} to="/portal/properties" onClick={() => toggle()}>Properties</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink tag={RRNavLink} to="/portal/mortgages" onClick={() => toggle()}>Mortgages</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink tag={RRNavLink} to="/portal/tenants" onClick={() => toggle()}>Tenants</NavLink>
                    </NavItem>
                </Nav>
                <Dropdown isOpen={profileDropdownOpen} toggle={profileDropdownToggle} className='profileDropdown'>
                    <DropdownToggle nav caret>
                        <img src={user.picture} className='profileImageRound'
                             alt={user.name}/>&nbsp;{user.name}
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-dark">
                        <DropdownItem onClick={() => toggle()} tag={RRNavLink}
                                      to="/portal/profile">Profile</DropdownItem>
                        <DropdownItem divider/>
                        <DropdownItem onClick={logoutUser} className='custom-nav-item'
                                      to="">Logout</DropdownItem>
                    </DropdownMenu>
                </Dropdown>
            </Collapse>
        </Navbar>
    );
}
