import Profile from "./profile/Profile";
import AddProperty from "./property/create-property/AddProperty";
import EditProperty from "./property/edit-property/EditProperty";
import ViewProperty from "./property/view-property/ViewProperty";
import Properties from "./property/Properties";
import AddPortfolio from "./portfolio/create-portfolio/AddPortfolio";
import EditPortfolio from "./portfolio/edit-portfolio/EditPortfolio";
import ViewPortfolio from "./portfolio/view-portfolio/ViewPortfolio";
import Portfolios from "./portfolio/Portfolios";
import AddMortgage from "./mortgage/create-mortgage/AddMortgage";
import EditMortgage from "./mortgage/edit-mortgage/EditMortgage";
import ViewMortgage from "./mortgage/view-mortgage/ViewMortgage";
import Mortgages from "./mortgage/Mortgages";
import AddTenant from "./tenant/create-tenant/AddTenant";
import EditTenant from "./tenant/edit-tenant/EditTenant";
import ViewTenant from "./tenant/view-tenant/ViewTenant";
import Tenants from "./tenant/Tenants";
import PortalHome from "./portal-home/PortalHome";

export const PortalAppRoutes = [
    {
        index: true,
        element: <PortalHome/>,
        children: []
    },
    {

        path: 'tenants',
        children: [
            {
                index: true,
                element: <Tenants/>
            },
            {
                path: ':id',
                element: <ViewTenant/>
            },
            {
                path: ':id/edit',
                element: <EditTenant/>
            },
            {
                path: 'create',
                element: <AddTenant/>
            }
        ]
    },
    {

        path: 'mortgages',
        children: [
            {
                index: true,
                element: <Mortgages/>
            },
            {
                path: ':id',
                element: <ViewMortgage/>
            },
            {
                path: ':id/edit',
                element: <EditMortgage/>
            },
            {
                path: 'create',
                element: <AddMortgage/>
            }
        ]
    },
    {

        // https://signpropertyllc.com/portfolios/create
        path: 'portfolios',
        children: [
            {
                index: true,
                element: <Portfolios/>
            },
            {
                path: ':id',
                element: <ViewPortfolio/>
            },
            {
                path: ':id/edit',
                element: <EditPortfolio/>
            },
            {
                path: 'create',
                element: <AddPortfolio/>
            }
        ]
    },
    {
        path: 'properties',
        children: [
            {
                index: true,
                element: <Properties/>
            },
            {
                path: ':id',
                element: <ViewProperty/>
            },
            {
                path: ':id/edit',
                element: <EditProperty/>
            },
            {
                path: 'create',
                element: <AddProperty/>
            }
        ]
    },
    {
        path: 'profile',
        element: <Profile/>
    }
]