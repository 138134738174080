import {useEffect, useState} from "react";
import {Col, DropdownItem, DropdownMenu, DropdownToggle, Row, Table, UncontrolledDropdown} from "reactstrap";
import {useAuth0} from "@auth0/auth0-react";
import {HousesFill} from "react-bootstrap-icons";
import {getMortgages} from "./mortgage.service";
import {getProperties} from "../property/property.service";
import SnapSpinner from "../../components/spinner/SnapSpinner";
import KebabVertical from "../../components/verticalKebab/KebabVertical";
import {Link} from 'react-router-dom';


export default function Mortgages() {
    const [isLoading, setIsLoading] = useState(true);
    const [mortgages, setMortgages] = useState([]);
    const [properties, setProperties] = useState([]);
    const {getAccessTokenSilently} = useAuth0();

    useEffect(() => {
        const fetchData = async () => {
            const token = await getAccessTokenSilently();
            try {
                const response = await getMortgages(token);
                setMortgages(response);
            } catch (error) {
                console.log("error", error);
            } finally {
                setIsLoading(false);
            }
        }
        fetchData();
    }, [getAccessTokenSilently])

    useEffect(() => {
        const fetchData = async () => {
            const token = await getAccessTokenSilently();
            try {
                const response = await getProperties(token);
                setProperties(response);
            } catch (error) {
                console.log("error", error);
            }
        }
        fetchData();
    }, [getAccessTokenSilently])

    if (isLoading) {
        return <SnapSpinner/>;
    }

    const mortgageRows = mortgages.length && properties.length ? (mortgages.map(mortgage =>
        <tr key={mortgage.id}>
            <th scope="row">{mortgage.id}</th>
            <td>{properties.find(p => p.id === mortgage.propertyId)?.name}</td>
            <th scope="row">{mortgage.lenderName}</th>
            <td>{properties.find(p => p.id === mortgage.propertyId)?.address1}</td>
            <td>${mortgage.monthlyAmount.toFixed(2)}</td>
            <td>
                <UncontrolledDropdown className="float-end" direction='left'>
                    <DropdownToggle
                        id="dropdownMenuButton"
                        tag="a" className="nav-link btn-circle"
                        style={{cursor: 'pointer'}}
                    >
                        <KebabVertical/>
                    </DropdownToggle>

                    <DropdownMenu aria-labelledby="dropdownMenuButton" className="dropdown-menu-dark">
                        <DropdownItem tag={Link} to={`${mortgage.id}`}>
                            View
                        </DropdownItem>

                        <DropdownItem tag={Link} to={`${mortgage.id}/edit`}>
                            Edit
                        </DropdownItem>
                    </DropdownMenu>
                </UncontrolledDropdown>
            </td>
        </tr>)) : null;

    return (
        <div style={{height: '100%'}}>
            <Row className='mb-2'>
                <Col>
                    <h1>Mortgages</h1>
                </Col>
                <Col sm={1} style={{marginBottom: '12px'}} className='align-items-right'>
                    <Link to='create'
                          className='btn btn-primary'
                          style={{width: '100px'}}>
                        <HousesFill size='20' className='align-middle'/>
                        <span className='align-middle'>+</span>
                    </Link>
                </Col>
            </Row>
            <Table responsive>
                <thead>
                <tr>
                    <th>#</th>
                    <th>Property</th>
                    <th>Lender</th>
                    <th>Address</th>
                    <th>Monthly $</th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                {mortgageRows}

                </tbody>
            </Table>
        </div>
    );
}